import { type Dispatch, type SetStateAction, useEffect, useState } from "react"

import memoizeOne, { type MemoizedFn } from "memoize-one"

import { THEME } from "@/3514/config"
import type { TEmptyCallback } from "@/shared/types/functions"

const breakpoints: { xs: number; sm: number; md: number; lg: number; xl: number; xxl: number } = {
    xs: 0,
    ...(Object.fromEntries(
        Object.entries(THEME.BREAKPOINTS).map(([key, value]: [string, string]): [string, number] => [
            key,
            parseInt(value, 10)
        ])
    ) as { sm: number; md: number; lg: number; xl: number; xxl: number })
} as const

type TScreenBreakpoints = Record<keyof typeof breakpoints, boolean>

const _getMatches: MemoizedFn<(width: number) => TScreenBreakpoints> = memoizeOne(function (
    windowInnerWidth: number
): TScreenBreakpoints {
    return Object.fromEntries(
        Object.keys(breakpoints).map((key: string): [string, boolean] => [
            key,
            key ===
                Object.entries(breakpoints)
                    .filter(([, value]: [string, number]): boolean => windowInnerWidth >= value)
                    .pop()?.[0]
        ])
    ) as TScreenBreakpoints
})

function useScreenBreakpoints(): TScreenBreakpoints {
    const [matches, setMatches]: [TScreenBreakpoints, Dispatch<SetStateAction<TScreenBreakpoints>>] =
        useState<TScreenBreakpoints>(_getMatches(window.innerWidth))

    useEffect((): TEmptyCallback => {
        const mediaQueries: { key: string; query: MediaQueryList }[] = Object.entries(breakpoints).map(
            ([key, value]: [string, number]): { key: string; query: MediaQueryList } => ({
                key,
                query: window.matchMedia(`(min-width: ${value}px)`)
            })
        )

        const handleChange: TEmptyCallback = (): void => setMatches(_getMatches(window.innerWidth))

        mediaQueries.forEach(({ query }: { key: string; query: MediaQueryList }): void =>
            query.addEventListener("change", handleChange)
        )

        return (): void =>
            mediaQueries.forEach(({ query }: { query: MediaQueryList }): void =>
                query.removeEventListener("change", handleChange)
            )
    }, [])

    return matches
}

export { useScreenBreakpoints }
export type { TScreenBreakpoints }
