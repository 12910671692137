import { type PayloadAction, type Slice, createSlice } from "@reduxjs/toolkit"

import type { IRootState } from "@/3514/store"

enum EModalId {
    AnalyticsDashboard = "AnalyticsDashBoard",
    SessionAttendance = "SessionAttendance",
    CoachingChatAbandonment = "CoachingChatAbandonment",
    CoachingChatFeedback = "CoachingChatFeedback",
    ChapterContentRequiredComponents = "ChapterContentRequiredComponents",
    FirstVisitDashboard = "FirstVisitDashboard"
}

type TModalProps = Record<string | number, unknown>

interface IModal<T = TModalProps> {
    id: EModalId
    isOpen: boolean
    modalProps?: T
}

interface IModalState {
    modals: IModal[]
}

const initialState: IModalState = {
    modals: []
}

const modalSlice: Slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showModal: ({ modals }: IRootState, { payload: { id, modalProps } }: PayloadAction<IModal>): void => (
            modals.push({
                id,
                isOpen: true,
                modalProps
            }),
            void 0
        ),
        hideModal: (state: IRootState, action: PayloadAction<{ id: EModalId }>): void => (
            (state.modals = state.modals.filter((modal: IModal): boolean => modal.id !== action.payload.id)), void 0
        ),
        hideAllModals: (state: IRootState): void => ((state.modals = []), void 0)
    }
})

const { reducer: modalReducer } = modalSlice
const { showModal, hideModal, hideAllModals } = modalSlice.actions

export { showModal, hideModal, hideAllModals, modalReducer, EModalId }
export type { IModal, IModalState, TModalProps }
