const _COLORS: {
    accent: string
    accentOld: string
    white: string
    black: string
    blue: Record<number, string>
    lightblue: Record<number, string>
    green: Record<number, string>
    gray: Record<number, string>
    yellow: Record<number, string>
    red: Record<number, string>
} = {
    accent: "#0404CB",
    accentOld: "#FD4D00",

    // common
    white: "#FFFFFF",
    black: "#000000",

    // dark blue
    blue: {
        100: "#0404CB",
        200: "#6868E0",
        300: "#B4B4EF",
        400: "#DADAF7",
        500: "#EDEDFB",
        600: "#F8F8FE"
    },
    // lightblue
    lightblue: {
        100: "#00AAFF",
        200: "#66CCFF",
        300: "#B3E6FF",
        400: "#E5F7FF",
        500: "#F2FBFF",
        600: "#EDF9FF"
    },
    // green
    green: {
        100: "#00ECA5",
        200: "#66F4C9",
        300: "#B3FAE4",
        400: "#E5FDF6"
    },
    // gray
    gray: {
        100: "#333333",
        200: "#525252",
        300: "#B0B0B0",
        400: "#E3E3E3",
        500: "#F3F3F3",
        23: "#737373" // TODO: double-check number
    },
    // yellow
    yellow: {
        100: "#FFF500",
        200: "#FFF966"
    },
    // red
    red: {
        100: "#FD4D00",
        200: "#FE9466",
        300: "#FFCAB3",
        400: "#FFEDE5",
        500: "#FFF6F2"
    }
}

const _BREAKPOINTS: { sm: string; md: string; lg: string; xl: string; xxl: string } = {
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1440px"
}

const _LINE_HEIGHTS: { 18: number; 20: number; 21: number; 24: number; 36: number; 54: number } = {
    18: 18,
    20: 20,
    21: 21,
    24: 24,
    36: 36,
    54: 54
}

const _FONT_SIZES: { 12: number; 13: number; 14: number; 16: number; 24: number; 36: number } = {
    12: 12,
    13: 13,
    14: 14,
    16: 16,
    24: 24,
    36: 36
}

const _FONT_WEIGHTS: { 300: number; 600: number; 800: number } = {
    300: 300,
    600: 600,
    800: 800
}

const TAILWIND_THEME = { COLORS: _COLORS, BREAKPOINTS: _BREAKPOINTS } // used for tailwind.config insertion
const THEME = {
    // used to manual pick
    COLORS: _COLORS,
    BREAKPOINTS: _BREAKPOINTS,
    FONTS_WEIGHTS: _FONT_WEIGHTS,
    FONT_SIZES: _FONT_SIZES,
    LINE_HEIGHTS: _LINE_HEIGHTS
}

export { THEME, TAILWIND_THEME }
