import { useCallback } from "react"

import { type UseQueryOptions, type UseQueryResult, useQuery } from "@tanstack/react-query"
import { AxiosError, type AxiosResponse } from "axios"

import { http } from "$/http"

import { ServerStateKeys } from "@/constants"
import User, { type UserApi } from "@/models/user"

import Urls from "./urls"

type TMeQueryError = AxiosError<UserApi, unknown>
type TMeQuery = UseQueryResult<User, TMeQueryError>
type TMeQueryParams = Pick<UseQueryOptions, "onSuccess" | "onError" | "enabled" | "staleTime">

function meAdapter(dto): User {
    return dto instanceof User ? dto : new User(dto)
}

async function meFetcher(): Promise<UserApi> {
    const { data }: AxiosResponse<UserApi> = await http.get(Urls.me())

    return data
}

function useMeQuery(params: TMeQueryParams = {}): ReturnType<(params: TMeQuery) => TMeQuery> {
    return useQuery<UserApi, TMeQueryError, User>({
        queryKey: [ServerStateKeys.User],
        queryFn: useCallback(meFetcher, []),
        select: useCallback(meAdapter, []),
        ...params
    })
}

export { useMeQuery, type TMeQuery, type TMeQueryParams, type TMeQueryError }
