import type { FC, ReactElement } from "react"

import sanitizeHtml from "sanitize-html"

import { isEmpty } from "$/utils/gates"

type TProps = { content: string; allowedTags?: string[] }

const BasicRenderer: FC<TProps> = ({ content = String(), allowedTags = undefined }: TProps): ReactElement => (
    <div
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(content, { ...(!isEmpty(allowedTags) && { allowedTags }) }) }}
    />
)

export { BasicRenderer }
